import "../styles/hero/eight.scss";
import { Link } from "react-router-dom";

const HeroEight = () => {
  return (
    <>
      <div className="hero__eight__container">
        <div className="hero__eight__wrapper">
          {/* <p className="hero__eight__tag">Simple. Trading. Demistified</p> */}
          <h1 className="hero__eight__text">
            <span> {""} Your</span>
            <span> {""} gateway</span>
            {/* <span> {""} gateway</span> */}
            <span> {""} to</span>
            <span> {""} global</span>
            <span> {""} financial </span>
            <span> {""} markets </span>
          </h1>

          <p className="hero__eight__supporting__text">
            Enjoy complete access to an ever expanding world of trading.
          </p>

          <span className="hero__eight__buttons">
            {/* <button className="hero__eight__button__white">Learn more</button> */}

            <Link to="/about">
              <button className="hero__eight__button__white">Learn more</button>
            </Link>

            <a href="https://app.avefinance.net">
              <button className="hero__eight__button__blue">Get started</button>
            </a>
          </span>
        </div>

        <figure className="hero__eight__rainbow__figure">
          <img src="/assets/rainbow_bright.png" alt="light" />
        </figure>
      </div>
    </>
  );
};

export default HeroEight;
